import type { Deal } from "@credix/credix-client";
import { PublicKey } from "@solana/web3.js";
import { MarketMetadata } from "../../api";
import _capitalize from "lodash/capitalize";

export function normalizeDeal(deals: Deal[], id: string | null) {
	if (id) {
		try {
			const pk = new PublicKey(id);
			const deal = deals?.find((deal) => deal.address.equals(pk));
			if (deal) return deal.name;
		} catch {
			// The given id is not a valid public key or it doesn't belong to this market
			// Either way we don't have a deal to get the name from.
		}
	}
	return "Deal not found";
}

type CrumbItem = {
	path: string;
	label: string;
};

export function buildCrumbItems(
	route: string,
	deals: Deal[],
	pathParts: string[],
	query: URLSearchParams,
	marketsMetada: MarketMetadata[]
): CrumbItem[] {
	return route
		.split("/")
		.filter(Boolean)
		.reduce(
			/**
			 * We use routePart to determine what type of route we are currently dealing with.
			 * Dynamic routes will not have a value and show up as [dynamic_route].
			 * We can then use pathParts to get the actual value of the dynamic route.
			 */
			(acc, routePart, i) => {
				const pathPart = pathParts[i];
				const previousRoute = acc[i];
				let label: string;

				if (routePart === "[marketplace]") {
					label = marketsMetada.find((m) => m.Seed === pathPart)?.Name ?? "";
					return acc.concat({ path: `${previousRoute.path}${pathPart}/`, label });
				}

				const dealId = query.get("dealId");
				const breadcrumbs: CrumbItem[] = [];

				if (["show", "facility-report"].includes(routePart)) {
					label = normalizeDeal(deals || [], dealId);

					breadcrumbs.push({ path: `${previousRoute.path}show/?dealId=${dealId}`, label });

					if (routePart === "show") {
						return acc.concat(breadcrumbs);
					}
				}

				if (routePart === "facility-report") {
					label = _capitalize(pathPart);
					breadcrumbs.push({
						path: `${previousRoute.path}${pathPart}/?dealId=${dealId}`,
						label: "Facility Report",
					});

					return acc.concat(breadcrumbs);
				}

				// Default case for non-existent routes.
				return acc.concat({
					path: `${previousRoute.path}${pathPart}`,
					label: _capitalize(routePart),
				});
			},
			[{ path: "/", label: "Home" }]
		);
}
