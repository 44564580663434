// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Modal as AntModal } from "antd";
import { ModalProps as AntModalProps } from "antd/lib/modal";

import { defineMessages, useIntl } from "react-intl";
import { classNames } from "@utils/format.utils";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button, ButtonProps } from "@credix/credix-components";

export enum ModalSize {
	SMALL,
	MEDIUM,
}

export enum ModalType {
	LIGHT,
	DARK,
}

const modalSizeMap = {
	[ModalSize.SMALL]: 600,
	[ModalSize.MEDIUM]: 940,
};

export interface ModalProps extends Omit<AntModalProps, "cancelButtonProps" | "okButtonProps"> {
	/**
	 * The modal dialog's title
	 */
	title?: React.ReactNode;
	/**
	 * Text of the OK button
	 */
	okText?: React.ReactNode;
	/**
	 * Text of the Cancel button
	 */
	cancelText?: React.ReactNode;
	/**
	 * Footer content, null if unset
	 */
	footer?: React.ReactNode;
	/**
	 * A function that will be called when a user clicks the OK element
	 */
	onOk?: React.MouseEventHandler<HTMLButtonElement>;
	/**
	 * A function that will be called when a user clicks the Cancel element
	 */
	onCancel?: React.MouseEventHandler<HTMLParagraphElement>;
	/**
	 * The OK button props
	 */
	okButtonProps?: ButtonProps;
	children?: AntModalProps["children"];
	size?: ModalSize;
	type?: ModalType;
	containerClassName?: string;
	bodyClassName?: string;
}

function ModalFooter({ okText, cancelText, okButtonProps, onOk, onCancel }) {
	const intl = useIntl();
	return (
		<div className="flex row-auto">
			<Button type="tertiary" className="mr-8" onClick={onCancel}>
				{cancelText || intl.formatMessage(MESSAGES.cancelModal)}
			</Button>
			<Button type="primary" className="font-mono text-sm ml-0" onClick={onOk} {...okButtonProps}>
				{okText || intl.formatMessage(MESSAGES.confirmModal)}
			</Button>
		</div>
	);
}

export function Modal({
	title,
	size = ModalSize.MEDIUM,
	type = ModalType.LIGHT,
	okText,
	cancelText,
	footer,
	onOk,
	onCancel,
	okButtonProps,
	children,
	centered,
	containerClassName: _containerClassName,
	bodyClassName: _bodyClassName,
	...props
}: ModalProps) {
	const titleClassName = classNames([
		"font-sans font-semibold text-inherit mb-0 mb-8 mt-0",
		centered && "text-center",
	]);
	const bodyClassName = classNames([
		"font-mono leading-relaxed overflow-y-scroll lg:max-h-[calc(100vh-550px)] max-h-[calc(100vh-350px)]",
		centered && "text-center",
		_bodyClassName,
	]);
	const containerClassName = classNames([
		"shadow-none pb-0 pr-0 border border-transparent rounded-md",
		type === ModalType.DARK
			? "bg-neutral-900 text-neutral-0"
			: _containerClassName || "bg-neutral-0",
	]);

	let footerContent = footer;

	if (footer === undefined) {
		footerContent = (
			<ModalFooter
				okText={okText}
				cancelText={cancelText}
				okButtonProps={okButtonProps}
				onOk={onOk}
				onCancel={onCancel}
			/>
		);
	}

	return (
		<AntModal
			width={modalSizeMap[size]}
			className={containerClassName}
			onCancel={onCancel}
			footer={footerContent}
			closable={footer !== undefined}
			closeIcon={
				<div className={`grid w-6 h-6 place-content-center rounded-full`}>
					<XMarkIcon className="w-3 h-3" />
				</div>
			}
			{...props}
		>
			{title && <h1 className={titleClassName}>{title}</h1>}
			<div className={bodyClassName}>{children}</div>
		</AntModal>
	);
}

const MESSAGES = defineMessages({
	confirmModal: {
		defaultMessage: "Ok",
		description: "Modal confirmation action",
	},
	cancelModal: {
		defaultMessage: "Cancel",
		description: "Modal cancel action",
	},
});
