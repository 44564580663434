import { CredixClient, Deal, DealStatus, Market } from "@credix/credix-client";
import { PublicKey } from "@solana/web3.js";

export const createCredixClient = (solanaClient: CredixClient) => ({
	getCredixPass: (market: Market, publicKey: PublicKey) => market.fetchCredixPass(publicKey),
	getMarket: (marketplace: string) => solanaClient.fetchMarket(marketplace),
	getDeals: (market: Market, status?: DealStatus) => market.fetchDeals(status),
	getTranches: (deals: Deal[]) => solanaClient.tranchesLoader.fetchForDeals(deals),
	getUserBaseBalance: (market: Market, publicKey: PublicKey) => market.userBaseBalance(publicKey),
	getRepaymentSchedules: (deals: Deal[]) =>
		solanaClient.repaymentScheduleLoader.fetchForDeals(deals),
	isMarketManager: (market: Market, publicKey: PublicKey) => market.isManager(publicKey),
});
