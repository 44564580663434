// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useSIWS } from "@components/SIWS/useSIWS";
import { Banner, BannerType } from "@credix/credix-components";
import { onboardingProgressSelector } from "@state/onboardingSlice";
import { useStore } from "@state/useStore";
import { defineMessages, useIntl } from "react-intl";
import { ONBOARDING_STEPPER_MESSAGES } from "./OnboardingStepper";
import { WalletState } from "@components/SIWS/SIWS.types";
import { useCredixPass } from "@hooks/useCredixPass";

export const OnboardingBanner = () => {
	const intl = useIntl();
	const { walletState } = useSIWS();
	const { credixPass } = useCredixPass();
	const setOnboardingStep = useStore((store) => store.setOnboardingStep);
	const onboardingStep = useStore(onboardingProgressSelector(walletState));
	const stepMessage = ONBOARDING_STEPPER_MESSAGES[onboardingStep];

	if (walletState !== WalletState.SIGNED_IN || credixPass || !stepMessage) {
		return null;
	}

	return (
		<Banner
			type={BannerType.WARNING}
			text={
				<>
					<p className="mb-1">{intl.formatMessage(MESSAGES.onboardingBannerText)}</p>
					<span>
						{intl.formatMessage(MESSAGES.onboardingBannerStep, {
							step: (
								<a
									className="text-neutral-900 font-semibold underline"
									onClick={() => setOnboardingStep(onboardingStep)}
								>
									{intl.formatMessage(stepMessage)}
								</a>
							),
						})}
					</span>
				</>
			}
		/>
	);
};

const MESSAGES = defineMessages({
	onboardingBannerText: {
		defaultMessage:
			"Hi there. Seems like you needed a break. There's only a few steps left before you can start investing.",
		description: "Onboarding banner title message",
	},
	onboardingBannerStep: {
		defaultMessage: "Next step: {step}",
		description: "Onboarding banner next step message",
	},
});
