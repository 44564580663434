import { config } from "@config";
import axios from "axios";

const httpClient = axios.create({
	baseURL: config.notionConfig.baseUrl,
});

export const notionApiClient = {
	getMarketsMetadata: () => {
		return httpClient.get<MarketMetadata[]>(`/table/${config.notionConfig.marketsMetadataTable}`);
	},
	getDealsMetadata: () => {
		return httpClient.get<DealMetadata[]>(`/table/${config.notionConfig.dealsMetadataTable}`);
	},
	getBorrowerMetadata: () => {
		return httpClient.get<BorrowerMetadata[]>(
			`/table/${config.notionConfig.borrowerMetadataTable}`
		);
	},
};

export type MarketMetadata = {
	id: string;
	"Link 1 url"?: string;
	"Link 1 name"?: string;
	"Link 2 url"?: string;
	"Link 2 name"?: string;
	"Show stats"?: boolean;
	"Show deals"?: boolean;
	link?: string; // delete once link1 and link2 are in production
	Description?: string;
	Tags?: string[];
	Environment?: string[];
	Term?: string;
	Name: string;
	"Underlying assets"?: string[];
	"Public Key": string[];
	"Pool description": string;
	"Min investment"?: number;
	"Short description"?: string;
	"Show on homepage"?: boolean;
	"Est. APY"?: number;
	Seed?: string;
	Country?: string[];
	company?: string; // delete once link1 and link2 are in production
};

export type EnvironmentTag = "Live" | "Live Premainnet" | "Live Devnet";
export type DealMetadata = {
	Country: string;
	Name: string;
	"Public Key": string[];
	Tags: EnvironmentTag;
	"Underlying assets": string;
	Description: string;
	id: string;
	"Borrower Website": string;
	Borrower: string;
};

export type BorrowerMetadata = {
	id: string;
	logo?: { url: string }[];
	"Public Key"?: string[];
	"Short Description"?: string;
	Founded?: string;
	Founders?: string[];
	"Underlying assets"?: string;
	Website?: string;
	Country?: string;
	Name: string;
};
