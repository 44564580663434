import { config } from "@config";
import { COMPLIANCE_STATUS } from "@credix/credix-common";
import message from "@message";
import notification from "@notification";
import { useStore } from "@state/useStore";
import axios from "axios";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";

// TODO: make this reusable
const httpClient = axios.create({ withCredentials: true, baseURL: config.apiUrl });

export const useTerms = (callBack?: () => unknown) => {
	const intl = useIntl();
	const router = useRouter();
	const { marketplace } = router.query;
	const [terms, setTerms] = useState(null);
	const setTosStatus = useStore((state) => state.setComplianceStatus);

	useEffect(() => {
		const getTerms = async () => {
			const resp = await httpClient.get(`/markets/${marketplace}/terms`);
			setTerms(resp.data);
		};
		marketplace && getTerms();
	}, [marketplace]);

	const acceptTermsOfService = async () => {
		const hide = message.loading({
			content: intl.formatMessage(MESSAGES.termsOfServiceAcceptLoading),
		});

		try {
			await httpClient.post(`markets/${marketplace}/terms-of-service/accept`);
			notification.success({
				message: intl.formatMessage(MESSAGES.termsOfServiceAcceptSuccess),
			});
			setTosStatus(COMPLIANCE_STATUS.VALID);
			callBack?.();
			hide();
		} catch (error) {
			hide();
			notification.error({
				message: intl.formatMessage(MESSAGES.termsOfServiceAcceptFailure),
				error: error as Error,
			});
			setTosStatus(COMPLIANCE_STATUS.NOT_ACCEPTED);
		}
	};

	return { terms, acceptTermsOfService };
};

const MESSAGES = defineMessages({
	termsOfServiceAcceptLoading: {
		defaultMessage: "Accepting terms of use...",
		description: "Terms of use accept loading",
	},
	termsOfServiceAcceptSuccess: {
		defaultMessage: "Terms of use accepted",
		description: "Terms of use accepted",
	},
	termsOfServiceAcceptFailure: {
		defaultMessage: "On-chain whitelisting not successful, please try again in a few minutes",
		description: "Terms of use accept failure",
	},
});
