import { config } from "@config";
import { VAAInfo } from "@utils/wormhole.utils";
import axios from "axios";

const httpClient = axios.create({
	baseURL: config.ethConfig.wormholeConfig.rpc,
});

export const wormholeClient = {
	getEthVAABytes: (vaaInfo: VAAInfo) => {
		return httpClient.get<VaaBytesResponse>(
			`/v1/signed_vaa/2/${vaaInfo.emitterAddress}/${vaaInfo.sequence}`
		);
	},
	getSolVAABytes: (vaaInfo: VAAInfo) => {
		return httpClient.get<VaaBytesResponse>(
			`/v1/signed_vaa/1/${vaaInfo.emitterAddress}/${vaaInfo.sequence}`
		);
	},
};

export type VaaBytesResponse = {
	vaaBytes: string;
};
