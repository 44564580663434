import { Container } from "@components/Container";
import { CredixLogo, Divider } from "@credix/credix-components";
import { marketSelector } from "@state/selectors";
import { useStore } from "@state/useStore";
import Link from "next/link";
import { ConnectWalletButton } from "../ConnectWalletButton";
import { CrossChainMenuItems } from "./CrossChainMenuItems";
import { UserBaseBalance } from "./UserBaseBalance";

export interface TopMenuProps {
	showLogo?: boolean;
}

export const TopMenu = ({ showLogo = true }: TopMenuProps) => {
	const market = useStore(marketSelector);

	return (
		<>
			<Container>
				<div className="bg-neutral-100 w-full py-4">
					<div className={`w-full flex space-x-12 ${showLogo ? "justify-between" : "justify-end"}`}>
						{showLogo && (
							<div className="flex flex-col justify-center">
								<div>
									<Link href="/">
										<a className="flex items-center">
											<CredixLogo mode="dark" />
										</a>
									</Link>
								</div>
							</div>
						)}
						{market ? (
							<div className="flex items-center space-x-5">
								{market.isCrossChain ? <CrossChainMenuItems /> : <DefaultMenuItems />}
							</div>
						) : undefined}
					</div>
				</div>
			</Container>
			<Divider />
		</>
	);
};

const DefaultMenuItems = () => {
	return (
		<>
			<UserBaseBalance />
			<ConnectWalletButton />
		</>
	);
};
