import { config } from "@config";
import { PublicKey } from "@solana/web3.js";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { MarketMetadata } from "../api";
import { useClient } from "./useClient";
import { EnvironmentName } from "@credix/credix-common";

export function useMarketsMetadata(address?: PublicKey | undefined, seed?: string | undefined) {
	const client = useClient();
	const [marketsMetadata, setMarketsMetadata] = useState<MarketMetadata[]>([]);
	const [selectedMarketMetadata, setSelectedMarketMetadata] = useState<
		MarketMetadata | undefined
	>();

	const { isLoading, data } = useQuery({
		queryKey: ["marketsMetadata"],
		queryFn: async () => {
			const res = await client.getMarketsMetadata();
			return res.data;
		},
		staleTime: 1000 * 60 * 5, // 5 minutes
	});

	useEffect(() => {
		if (isLoading) return;

		const marketsMetaData = data ?? [];

		/**
		 * The Markets in the Notion table are tagged with an environment.
		 * We filter out the markets that we don't want to show depending on the environment in the notion table
		 */
		const filteredMarketsMetaData = marketsMetaData.filter((market) => {
			const environment = config.clusterConfig.name;
			const filterValue =
				environment === EnvironmentName.LOCALNET ? EnvironmentName.DEVNET : environment;

			return market.Environment?.includes(filterValue);
		});

		setMarketsMetadata(filteredMarketsMetaData);

		if (address) {
			setSelectedMarketMetadata(
				getMarketMetadata({
					marketsMetadata: filteredMarketsMetaData,
					address: address.toString(),
					seed,
				})
			);
		}
	}, [isLoading, data, address, seed]);

	return useMemo(
		() => ({ marketsMetadata, selectedMarketMetadata, isLoading }),
		[marketsMetadata, selectedMarketMetadata, isLoading]
	);
}

const getMarketMetadata = ({
	marketsMetadata,
	address,
	seed,
}: {
	marketsMetadata: MarketMetadata[];
	address: string;
	seed?: string | undefined;
}): MarketMetadata | undefined => {
	// HACK: Use devnet MarketMedata localnet as we don't have records in the table for localnet
	if (config.clusterConfig.name === EnvironmentName.LOCALNET) {
		if (seed) {
			return marketsMetadata.find((market) => {
				return market.Seed === seed;
			});
		}

		// If no seed, return a dummy object. Backwards compatibility to what we had. I migth be removed later.
		return {
			Name: "Market name",
			"Pool description": "this is the description of the pool",
			id: "123",
			"Link 1 url": "app.credix.finance",
			"Link 1 name": "link 1",
			"Link 2 url": "app.credix.finance",
			"Link 2 name": "link 2",
			"Show stats": true,
			"Show deals": true,
			link: "app.credix.finance",
			Description: "this is the description of the market",
			Term: "Open ended",
			"Underlying assets": ["Test Asset 1", "Test Asset 2"],
			"Public Key": [""],
			"Min investment": 10_000,
			"Short description": "short description",
			"Show on homepage": true,
			"Est. APY": 12,
			Country: ["Brazil"],
			company: "Credix",
			Seed: "credix-maketplace",
		};
	}

	return marketsMetadata.find((market) => market["Public Key"].includes(address.toString()));
};
