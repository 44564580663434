import { Breadcrumbs as CredixBreadcrumbs } from "@credix/credix-components";
import { useStore } from "@state/useStore";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { FunctionComponent, useMemo } from "react";
import { buildCrumbItems } from "./helpers";
import { useMarketsMetadata } from "@hooks/useMarketsMetadata";

const Crumbs: FunctionComponent = () => {
	const router = useRouter();
	const [path, queryParams] = router.asPath.split("?");
	const deals = useStore((state) => state.deals);
	const { marketsMetadata } = useMarketsMetadata();
	const pathParts = path.split("/").filter(Boolean);
	const query = useMemo(() => new URLSearchParams(queryParams), [queryParams]);

	const items = useMemo(() => {
		return buildCrumbItems(router.route, deals, pathParts, query, marketsMetadata).map(
			({ path, label }) => (
				<Link href={path} key={path}>
					{label}
				</Link>
			)
		);
	}, [router.route, deals, pathParts, query]);

	return <CredixBreadcrumbs items={items} className="mb-10" />;
};

export const Breadcrumbs = dynamic(() => Promise.resolve(Crumbs), { ssr: false });
