import React, { ReactNode } from "react";
import { SideMenu } from "@components/SideMenu";
import { TopMenu } from "@components/top-menu/TopMenu";
import { Footer } from "@components/Footer";
import { routes } from "../consts";
import { Breadcrumbs } from "./Breadcrumbs";
import { Container } from "./Container";
import { classNames } from "@utils/format.utils";
import { ReAcceptTermsBanner } from "./terms-of-use/ReAcceptTermsBanner";
import { OnboardingBanner } from "./onboarding/OnboardingBanner";
import { RiskBanner } from "./RiskBanner";

interface LayoutProps {
	children?: ReactNode;
}

const WithSideMenu = ({ children }: LayoutProps) => {
	return (
		<div className="grid grid-cols-layout">
			<SideMenu menuItems={routes} />
			{children}
		</div>
	);
};

interface WithContainerProps extends LayoutProps {
	className?: string;
}

const WithContainer = ({ className, children }: WithContainerProps) => {
	return <Container className={classNames(["pb-20", className])}>{children}</Container>;
};

interface WithMainMenuProps extends LayoutProps {
	showLogo?: boolean;
	showBreadcrumbs?: boolean;
	container?: boolean;
}

const WithMainMenu = ({ children, showLogo = true, showBreadcrumbs }: WithMainMenuProps) => {
	return (
		<div className="grid grid-rows-layout min-h-screen">
			<ReAcceptTermsBanner />
			<RiskBanner />
			<OnboardingBanner />
			<TopMenu showLogo={showLogo} />
			<div>
				{showBreadcrumbs && (
					<Container className="pt-20">
						<Breadcrumbs />
					</Container>
				)}
				{children}
			</div>
			<div>
				<Footer />
			</div>
		</div>
	);
};

const Layout = ({ children }: LayoutProps) => <>{children}</>;

Layout.WithSideMenu = WithSideMenu;
Layout.WithMainMenu = WithMainMenu;
Layout.WithContainer = WithContainer;

export default Layout;
