import axios from "axios";

const httpClient = axios.create({
	baseURL: "https://credix-market-stats.credix.workers.dev/",
});

type CloudflareGetMarketStatsParams = { cached?: "True"; market?: string };
export const cloudflareApiClient = {
	getMarketStats: (params?: CloudflareGetMarketStatsParams) => {
		return httpClient.get<MarketStatsResponse>("/", {
			params: {
				cached: "True",
				...params,
			},
		});
	},
};

export type MarketStatsResponse = {
	public_key: string;
	seed: string;
	lp_token_price: number;
	TVL: number;
	pool_amount: number;
	total_outstanding_credit: number;
	treasury_amount: number;
	weighted_average_financing_fee: number;
	interest_repaid: number;
	apy_30_d_trailing: number;
	apy_90_d_trailing: number;
};
