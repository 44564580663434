import { useCredixClient } from "@credix/credix-client";
import { createApiClient } from "api";
import { useMemo } from "react";

export const useClient = () => {
	const credixClient = useCredixClient();
	const apiClient = useMemo(() => createApiClient(credixClient), [credixClient]);

	return apiClient;
};
