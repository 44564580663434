import { Button } from "@credix/credix-components";
import { slicedBased58Key } from "@utils/format.utils";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { Modal } from "../Modal";

export const CrossChainMenuItems = () => {
	const intl = useIntl();
	const { address, isConnected } = useAccount();
	const { disconnect } = useDisconnect();
	const { connect, connectors } = useConnect();
	const [open, setOpen] = useState(false);

	const openConnectorModal = () => setOpen(true);
	const closeConnectorModal = () => setOpen(false);

	if (isConnected)
		return (
			<div className="flex items-center space-x-4">
				<div>{slicedBased58Key(address as string)}</div>
				<Button onClick={() => disconnect()}>{intl.formatMessage(MESSAGES.disconnect)}</Button>
			</div>
		);

	return (
		<>
			<Modal
				open={open}
				title={intl.formatMessage(MESSAGES.chooseWallet)}
				onCancel={closeConnectorModal}
				onOk={closeConnectorModal}
				containerClassName="bg-neutral-100"
				bodyClassName="py-8 space-y-8"
				footer={null}
			>
				{connectors.map((connector) => (
					<div key={connector.id} className="flex space-x-8 items-center">
						{/* HACK: Since the custom InjectedConnector is not working at the moment we don't have a dedicated Phantom connector. Phantom highjacks the MetaMask connector allowing the user to choose between MetaMask and Phantom. */}
						<div>{connector.name === "MetaMask" ? "MetaMask/Phantom" : connector.name}</div>
						<Button type="secondary" onClick={() => connect({ connector })}>
							{intl.formatMessage(MESSAGES.connect)}
						</Button>
					</div>
				))}
			</Modal>
			<Button type="primary" data-test="connect-wallet-button" onClick={openConnectorModal}>
				{intl.formatMessage(MESSAGES.connect)}
			</Button>
		</>
	);
};

const MESSAGES = defineMessages({
	chooseWallet: {
		defaultMessage: "Choose wallet",
		description: "Choose wallet modal title",
	},
	disconnect: {
		defaultMessage: "Disconnect",
		description: "Disconnect wallet modal button",
	},
	connect: {
		defaultMessage: "Connect Wallet",
		description: "Connect wallet modal button",
	},
});
