import { COMPLIANCE_STATUS } from "@credix/credix-common";
import { Banner, BannerType, Button } from "@credix/credix-components";
import { useTerms } from "@hooks/useTerms";
import { useStore } from "@state/useStore";
import { FunctionComponent, useState } from "react";
import { useIntl, defineMessages } from "react-intl";
import Terms from "./Terms";

export const ReAcceptTermsBanner: FunctionComponent = () => {
	const complianceStatus = useStore((state) => state.complianceStatus);
	const intl = useIntl();
	const [showTerms, setShowTerms] = useState(false);
	const { acceptTermsOfService } = useTerms();

	if (complianceStatus !== COMPLIANCE_STATUS.INVALID) {
		return null;
	}

	const onCancel = () => setShowTerms(false);
	const onView = () => setShowTerms(true);

	return (
		<>
			<Terms open={showTerms} onCancel={onCancel} />
			<Banner
				type={BannerType.WARNING}
				text={
					<div>
						<div className="font-mono text-base font-semibold">
							{intl.formatMessage(MESSAGES.content)}
						</div>
						<Button type="tertiary" className="h-min" onClick={onView}>
							{intl.formatMessage(MESSAGES.view)}
						</Button>
					</div>
				}
				action={
					<Button type="primary" onClick={acceptTermsOfService}>
						{intl.formatMessage(MESSAGES.acceptTerms)}
					</Button>
				}
			/>
		</>
	);
};

const MESSAGES = defineMessages({
	content: {
		defaultMessage: "We have updated our Terms of use, please review and accept",
		description: "Re-accept terms of use banner content",
	},
	view: {
		defaultMessage: "View updated Terms of use",
		description: "Re-accept terms of use banner view",
	},
	acceptTerms: {
		defaultMessage: "Accept terms of use",
		description: "Re-accept terms of use banner accept button text",
	},
});
