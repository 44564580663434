import { Button } from "@credix/credix-components";
import { defineMessages } from "@formatjs/intl";
import { useStore } from "@state/useStore";
import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { requireDesktop } from "./DesktopGuard";
import { WalletState } from "./SIWS/SIWS.types";
import { SIWSButton } from "./SIWS/SIWSButton";
import { useSIWS } from "./SIWS/useSIWS";

export const ConnectWalletButton: FunctionComponent = () => {
	const intl = useIntl();
	const { setIsConnectingWallet, showDesktopGuard } = useStore((state) => state);
	const { walletState } = useSIWS();

	return (
		<>
			{[WalletState.CONNECTED, WalletState.SIGNED_IN].includes(walletState) ? (
				<SIWSButton data-test="connected-wallet-button" />
			) : (
				<Button
					type="primary"
					data-test="connect-wallet-button"
					onClick={requireDesktop(() => setIsConnectingWallet(true), showDesktopGuard)}
				>
					{intl.formatMessage(MESSAGES.button)}
				</Button>
			)}
		</>
	);
};

const MESSAGES = defineMessages({
	button: {
		defaultMessage: "Connect your wallet",
		description: "Connect wallet modal button",
	},
});
