import { config } from "@config";
import {
	DepositFromRemoteDto,
	EligibilityCriteria,
	SecuritizeAccessCode,
	UserDto,
	WithdrawFromRemoteDto,
} from "@credix/credix-common";
import { ConfirmedTransactionMeta } from "@solana/web3.js";
import axios from "axios";

const httpClient = axios.create({ withCredentials: true, baseURL: config.apiUrl });

export const backendApiClient = {
	linkInvestorToOrganization: ({ organizationId, body }: LinkInvestorToOrgParams) => {
		return httpClient.post(`/eligibility/securitize/${organizationId}`, body);
	},
	checkEligibilityCriteria: ({ market }: CheckEligibilityCriteriaParams) => {
		return httpClient.get<EligibilityCriteria>(`/markets/${market}/compliance/check`);
	},
	getEligibilityCriteria: ({ organizationId }: { organizationId: string }) => {
		return httpClient.get<EligibilityCriteria | null>(`/eligibility/${organizationId}`);
	},
	getNonce: () => {
		return httpClient.get<string>("/auth/nonce");
	},
	getMarketTerms: ({ market }: GetMarketTermsParams) => {
		return httpClient.get<string>(`/markets/${market}/terms`);
	},
	acceptMarketTerms: ({ market }: AcceptMarketTermsParams) => {
		return httpClient.post<void>(`markets/${market}/terms-of-service/accept`);
	},
	depositFromRemote: ({ market, body }: DepositFromRemoteParams) => {
		return httpClient.post<ConfirmedTransactionMeta, any, DepositFromRemoteDto>(
			`/markets/${market}/deposit-from-remote`,
			body
		);
	},
	withdrawFromRemote: ({ market, body }: WithdrawFromRemoteParams) => {
		return httpClient.post<ConfirmedTransactionMeta, any, DepositFromRemoteDto>(
			`/markets/${market}/withdraw-from-remote`,
			body
		);
	},
	getMe: () => {
		return httpClient.get<UserDto>("/me");
	},
};

type LinkInvestorToOrgParams = {
	organizationId: string;
	body: SecuritizeAccessCode;
};
type GetMarketTermsParams = { market: string };
type AcceptMarketTermsParams = { market: string };
type CheckEligibilityCriteriaParams = { market: string };
type DepositFromRemoteParams = { market: string; body: DepositFromRemoteDto };
type WithdrawFromRemoteParams = { market: string; body: WithdrawFromRemoteDto };
