import { useTerms } from "@hooks/useTerms";
import { ModalProps as AntModalProps } from "antd/lib/modal";
import { defineMessages, useIntl } from "react-intl";
import { Modal } from "../Modal";

interface TermsProps {
	open: boolean;
	onCancel: AntModalProps["onCancel"];
	onAcceptTerms?: () => void;
}

export default function Terms({ open, onCancel, onAcceptTerms }: TermsProps) {
	const intl = useIntl();
	const { terms, acceptTermsOfService } = useTerms(onAcceptTerms);

	return (
		<Modal
			open={open}
			title={intl.formatMessage(MESSAGES.titleTerms)}
			okText={intl.formatMessage(MESSAGES.confirmTerms)}
			onOk={acceptTermsOfService}
			onCancel={onCancel}
			containerClassName="bg-neutral-100"
			bodyClassName="border border-neutral-400"
		>
			<div className="bg-neutral-0 p-2">
				{terms && <div id="terms-modal border-2" dangerouslySetInnerHTML={{ __html: terms }} />}
			</div>
		</Modal>
	);
}

const MESSAGES = defineMessages({
	titleTerms: {
		defaultMessage: "Terms of use",
		description: "Terms of use title",
	},
	confirmTerms: {
		defaultMessage: "Accept terms of use",
		description: "Terms of use confirmation action",
	},
});
