import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { CredixLogo } from "@credix/credix-components";
import { Route } from "../types/route.types";
import { defaultMarketplace } from "@consts";
import { classNames } from "../utils/format.utils";
import { useCredixPass } from "@hooks/useCredixPass";

export interface SideMenuProps {
	menuItems: Route[];
}

export const SideMenu = ({ menuItems }: SideMenuProps) => {
	const router = useRouter();
	const { credixPass } = useCredixPass();
	const route = router?.pathname.split("/").slice(2).join("");
	const marketplace = router?.query ? `/${router?.query.marketplace}` : defaultMarketplace;
	const items = menuItems.filter((i) => !i.isAllowed || (credixPass && i.isAllowed(credixPass)));
	const activeIdx = items.findIndex(({ path }) => route.startsWith(path.slice(1)));

	return (
		<div className="w-72 bg-neutral-900 h-screen pt-4 sticky top-0">
			<div className="text-neutral-50 ml-8 h-[50px] flex">
				<Link href="/">
					<a className="flex items-center">
						<CredixLogo mode="light" />
					</a>
				</Link>
			</div>
			<div className="mt-[145px]">
				{items.map(({ path, label }, idx) => (
					<SideMenuItem
						key={label}
						isActive={activeIdx === idx}
						path={`${marketplace}${path}`}
						label={label}
					/>
				))}
			</div>
		</div>
	);
};

interface SideMenuItemProps extends Route {
	isActive: boolean;
}

const SideMenuItem = ({ label, path, isActive }: SideMenuItemProps) => {
	return (
		<Link href={path}>
			<a
				className={classNames([
					"h-[60px] flex items-center group hover:bg-neutral-10",
					isActive ? "bg-neutral-50 text-neutral-900" : "bg-neutral-900 text-neutral-50",
				])}
			>
				<div className="border-0 h-8 border-l-[3px] border-neutral-900 border-solid w-full pl-[23px] flex items-center font-semibold group-hover:border-neutral-10 group-hover:text-bold">
					<span className="pl-[18px] text-base">{label}</span>
				</div>
			</a>
		</Link>
	);
};
