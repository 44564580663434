import { useSIWS } from "@components/SIWS/useSIWS";
import { Banner, BannerType } from "@credix/credix-components";
import { isAxiosError } from "axios";
import { defineMessages, useIntl } from "react-intl";
import _isNil from "lodash/isNil";

export const RiskBanner = () => {
	const intl = useIntl();
	const { error } = useSIWS();

	if (_isNil(error) || !isAxiosError(error)) return null;

	// Handling first any specific error we throw from our API related to third party services
	if (error.response?.data?.exceptionType === "RiskException") {
		console.error(error.response.data.thirdPartyServiceErrorMessage || error.message);
		/**
		 * This is a fallback for any other error like a specific 500 error we throw when the RiskService in the API
		 * receives an error from the Chainalysis API.
		 */
		return (
			<Banner
				type={BannerType.ERROR}
				text={
					<p className="mb-0">
						{intl.formatMessage(MESSAGES.unexpectedErrorFirstLine)}
						<br />
						{intl.formatMessage(MESSAGES.unexpectedErrorSecondLine, {
							emailLink: <RiskBannerEmailLink />,
						})}
					</p>
				}
			/>
		);
	}

	// In normal circumstances, when there's a 400 error, it's because the user has a too high risk score
	if (error.response?.status === 400) {
		return (
			<Banner
				type={BannerType.ERROR}
				text={
					<p className="mb-0">
						{intl.formatMessage(MESSAGES.tooHighFirstLine)}
						<br />
						{intl.formatMessage(MESSAGES.tooHighSecondLine, {
							emailLink: <RiskBannerEmailLink />,
						})}
					</p>
				}
			/>
		);
	}

	// If we don't have a specific error to show, we don't show anything
	return null;
};

const MESSAGES = defineMessages({
	tooHighFirstLine: {
		defaultMessage: "We are performing due diligence on your historical on-chain activity.",
		description: "Risk banner message first line, risk is too high",
	},
	tooHighSecondLine: {
		defaultMessage: "Please get in touch with {emailLink} for more information.",
		description: "Risk banner message second line, risk is too high",
	},
	unexpectedErrorFirstLine: {
		defaultMessage:
			"It seems like there was a technical issue preventing us from performing due diligence on your historical",
		description: "Risk banner message first line, RiskException unexpected error",
	},
	unexpectedErrorSecondLine: {
		defaultMessage: "on-chain activity. Try again or contact {emailLink} if the issue persists.",
		description: "Risk banner message second line, RiskException unexpected error",
	},
});

function RiskBannerEmailLink() {
	return (
		<a
			href="mailto:info@credix.finance"
			target="_blank"
			rel="noopener noreferrer"
			className="text-neutral-0 underline"
		>
			info@credix.finance
		</a>
	);
}
