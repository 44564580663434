import { CredixClient } from "@credix/credix-client";
import { backendApiClient } from "./backend";
import { cloudflareApiClient } from "./cloudflare";
import { notionApiClient } from "./notion";
import { createCredixClient } from "./solana";
import { wormholeClient } from "./wormhole";
export type { BorrowerMetadata, DealMetadata, MarketMetadata } from "./notion";

export const createApiClient = (client: CredixClient) => {
	const credixClient = createCredixClient(client);

	return {
		linkInvestorToOrganization: backendApiClient.linkInvestorToOrganization,
		checkEligibilityCriteria: backendApiClient.checkEligibilityCriteria,
		getEligibilityCriteria: backendApiClient.getEligibilityCriteria,
		getNonce: backendApiClient.getNonce,
		getMarketTerms: backendApiClient.getMarketTerms,
		acceptMarketTerms: backendApiClient.acceptMarketTerms,
		depositFromRemote: backendApiClient.depositFromRemote,
		withdrawFromRemote: backendApiClient.withdrawFromRemote,
		getMe: backendApiClient.getMe,
		getMarketStats: cloudflareApiClient.getMarketStats,
		getMarketsMetadata: notionApiClient.getMarketsMetadata,
		getDealsMetadata: notionApiClient.getDealsMetadata,
		getBorrowerMetadata: notionApiClient.getBorrowerMetadata,
		getEthVaaBytes: wormholeClient.getEthVAABytes,
		getSolVaaBytes: wormholeClient.getSolVAABytes,
		getCredixPass: credixClient.getCredixPass,
		getMarket: credixClient.getMarket,
		getDeals: credixClient.getDeals,
		getTranches: credixClient.getTranches,
		getUserBaseBalance: credixClient.getUserBaseBalance,
		getRepaymentSchedules: credixClient.getRepaymentSchedules,
		isMarketManager: credixClient.isMarketManager,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	};
};
