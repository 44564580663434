import { useUser } from "@contexts/UserContext";
import { baseMintSymbolSelector } from "@state/selectors";
import { useStore } from "@state/useStore";
import { renderCurrency } from "@utils/format.utils";
import { defineMessages, useIntl } from "react-intl";
import { WalletState } from "../SIWS/SIWS.types";
import { useSIWS } from "../SIWS/useSIWS";

export const UserBaseBalance = () => {
	const intl = useIntl();
	const { walletState } = useSIWS();
	const { baseBalance } = useUser();
	const baseMintSymbol = useStore(baseMintSymbolSelector);

	if (walletState !== WalletState.SIGNED_IN) return null;

	return (
		<span
			className="font-mono text-base font-semibold text-neutral-800"
			data-testid="user-base-balance"
		>
			{intl.formatMessage(MESSAGES.yourBalance)} {renderCurrency(baseBalance, baseMintSymbol)}
		</span>
	);
};

const MESSAGES = defineMessages({
	yourBalance: {
		defaultMessage: "Your balance:",
		description: "User base balance label",
	},
});
